.recharts-legend-item-text {
  font-weight: 500 !important;
  font-size: 12px !important;
}

.recharts-cartesian-axis-tick {
  font-size: 11px;
}

line.recharts-cartesian-axis-line {
  stroke: none;
}

.recharts-tooltip-label {
  font-size: 14px;
  font-weight: 500;
}

.recharts-toopltip-item-list {
  font-size: 12px !important;
}

.recharts-tooltip-item-name {
  font-weight: 500 !important;
  font-size: 12px !important;
}

.recharts-tooltip-item-value {
  font-size: 12px !important;
}

.recharts-tooltip-item-separator {
  font-size: 12px !important;
}

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
