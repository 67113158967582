.otp-input {
  height: 67px;
  width: 45px;
  margin: 0 3px;
  text-align: center;
  font-size: 30px;
  color: #444;
  border-radius: 10px;
  border: 1px solid #888;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
