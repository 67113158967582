.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #f2f2f2;
  border: 1px solid var(--fc-border-color, #f2f2f2);
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid #f2f2f2;
  border: 1px solid var(--fc-border-color, #f2f2f2);
}

.fc .fc-daygrid-day-number {
  font-size: 11px;
  color: #646464;
  padding: 8px;
}

.fc-daygrid-day-events {
  padding-top: 5px;
}

.fc-day-today .fc-daygrid-day-top {
  background-color: #7570ea;
}

.fc-day-today .fc-daygrid-day-number {
  color: #ffffff;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}

.fc .fc-daygrid-more-link {
  color: #7570ea;
  font-size: 12px;
  padding: 0 3px 0 3px;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: transparent;
}

.fc-col-header-cell-cushion {
  padding: 10px 8px !important;
}

.fc-timeGridDay-view .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  padding: 10px 8px !important;
}

.fc-dayGridMonth-view th .fc-col-header-cell-cushion {
  font-size: 12px;
  color: #646464;
  font-weight: 400;
}

.fc-timegrid-slot-label-frame .fc-timegrid-slot-label-cushion {
  color: #646464;
  font-size: 10px;
  line-height: 12px;
}

.fc-timeGridWeek-view .fc-col-header-cell-cushion {
  font-size: 12px;
  color: #646464;
  font-weight: 400;
}

.fc-timeGridWeek-view .fc-event {
  white-space: nowrap;
}

.fc-timeGridWeek-view .fc-day-today .fc-scrollgrid-sync-inner {
  background-color: #7570ea;
}

.fc-timeGridWeek-view .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  color: #ffffff;
}

.fc-timeGridDay-view .fc-col-header-cell-cushion {
  font-size: 20px;
  color: #646464;
  font-weight: 600;
  padding: 10px 4px !important;
}

.fc-timeGridDay-view .fc-day-today .fc-scrollgrid-sync-inner {
  background-color: #f2f2f2;
}

.fc-timeGridDay-view .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  color: #9e9e9e;
}

.fc-media-screen .fc-timegrid-now-indicator-container {
  position: unset;
}

.fc .fc-timegrid-now-indicator-line {
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-color: #ff7705;
  background-color: #ff7705;
}

.fc-timeGridWeek-view .fc-timegrid-now-indicator-arrow {
  display: none;
}

.fc-timeGridWeek-view .fc-timegrid-now-indicator-line::before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  width: 8px;
  height: 8px;
  background-color: #ff7705;
  border-radius: 50%;
}

.fc .fc-toolbar-chunk .fc-button-group {
  background-color: #f2f2f2 !important;
  border-radius: 8px;
  padding: 0.15em 0.3em;
}

.fc .fc-button-primary {
  border: none;
  border-color: transparent;
  color: #646464;
  text-transform: capitalize;
  background-color: transparent;
  font-size: 12px;
  padding: 0.6em 1.5em;
}

.fc .fc-button-primary.fc-button-active {
  background-color: #fcfcfc !important;
  color: #7570ea !important;
  font-weight: 600;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px !important;
  height: 95%;
  outline: none;
}

.fc .fc-button-primary:hover {
  color: #646464;
  font-weight: 600;
  background-color: transparent;
}

.fc .fc-button-primary:active {
  color: #646464 !important;
  font-weight: 600;
  background-color: transparent !important;
}

.fc .fc-today-button:disabled {
  background-color: #fcfcfc !important;
  color: #7570ea !important;
  font-weight: 600;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px !important;
  height: 95%;
  outline: none;
  opacity: 1;
}

.fc .fc-today-button {
  border: none;
  border-color: transparent;
  color: #646464;
  text-transform: capitalize;
  background-color: transparent;
  font-size: 12px;
  padding: 0.6em 1.5em;
  background-color: #f2f2f2 !important;
  border-radius: 8px;
}

.fc-scrollgrid-section-header .fc-scroller {
  overflow: hidden !important;
  background-color: #f2f2f2 !important;
}

.fc-scroller {
  height: auto !important;
  overflow-y: auto;
}

.fc-prev-button {
  padding: 0.6em 0.75em !important;
}

.fc-next-button {
  padding: 0.6em 0.75em !important;
}
