@media only screen and (max-width: 600px) {
  .multi-date-picker {
    width: 75% !important;
  }
  .rmdp-wrapper {
    width: 100% !important;
  }
  .rmdp-panel {
    width: 100% !important;
    max-width: 100% !important;
  }
  .rmdp-panel-body {
    display: flex !important;
    justify-content: space-between !important;
    flex-wrap: wrap !important;
  }
  .rmdp-panel-body li {
    width: 80% !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .rmdp-panel-body li span {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .b-deselect {
    line-height: auto !important;
  }
}

@media only screen and (min-width: 601px) {
  .multi-date-picker {
    width: 75% !important;
  }
  .rmdp-wrapper {
    width: 100% !important;
  }
  .rmdp-panel {
    width: 100% !important;
    max-width: 100% !important;
  }
  .rmdp-panel-body {
    display: flex !important;
    justify-content: space-between !important;
    flex-wrap: wrap !important;
  }
  .rmdp-panel-body li {
    width: 45% !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .rmdp-panel-body li span {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .b-deselect {
    line-height: auto !important;
  }
}

.date-select--availability {
  background-color: #0374d5;
  opacity: 0.25;
}

.date-select--unavailability {
  background-color: #788b9d;
  opacity: 0.25;
}
